import { DataLoadStatus } from 'src/interface/command-center';
import { QueryParams } from 'src/interface/utility';
import { buildTraceId } from 'src/tools/tracing/buildTraceId';
import { traceMap } from 'src/tools/tracing/constants';

interface RequestOptions<Body> {
  params?: QueryParams;
  body?: Body;
  token?: string;
}

export const prepareRequest = <Body>(
  url: string,
  { params, body, token }: RequestOptions<Body> = {},
) => {
  let serializedUrl = url;
  let serializedBody;
  const headers: HeadersInit = {};
  if (token) {
    const traceId = buildTraceId();
    // TODO: make a scalable way to keep the tracemap size under control
    if (traceMap.size > 1000) {
      traceMap.clear();
    }
    traceMap.set(url, traceId);
    headers.traceparent = traceId;
    headers.Authorization = `Bearer ${token}`;
  }
  if (params) {
    const queryParams = params
      ? new URLSearchParams(params as Record<string, string>).toString()
      : '';

    if (queryParams !== '') {
      serializedUrl = `${serializedUrl}?${queryParams}`;
    }
  }
  if (body) {
    serializedBody = JSON.stringify(body);
    headers['Content-Type'] = 'application/json';
  }
  return {
    serializedUrl,
    headers,
    serializedBody,
  };
};

type ApiPathSets<
  PathSetName extends string,
  PathName extends string,
  PathParam,
> = {
  [pathSetName in PathSetName]: {
    [pathName in PathName]: (...args: PathParam[]) => string;
  };
};

export function convertToAbsolutePaths<
  PathSets extends ApiPathSets<PathSetName, PathName, PathParam>,
  PathSetName extends string,
  PathName extends string,
  PathParam,
>(baseUrl: string, pathSets: PathSets): PathSets {
  const localPathSets = {} as PathSets;

  Object.entries(pathSets).forEach(
    ([pathSetName, pathSet]: [string, PathSets[PathSetName]]) => {
      localPathSets[pathSetName] = Object.fromEntries(
        Object.entries(pathSet).map(
          ([pathName, pathGenerator]: [
            string,
            PathSets[PathSetName][PathName],
          ]) => {
            return [
              pathName,
              (...args: PathParam[]) => `${baseUrl}${pathGenerator(...args)}`,
            ];
          },
        ),
      );
    },
  );

  return localPathSets;
}

export const combineQueryStatuses = (
  ...statuses: DataLoadStatus[]
): DataLoadStatus =>
  statuses.every((status) => status === 'success')
    ? 'success'
    : statuses.some((status) => status === 'error')
    ? 'error'
    : 'loading';
