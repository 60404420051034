import clsx from 'clsx';
import {
  ChangeEvent,
  MouseEvent,
  ReactNode,
  Ref,
  forwardRef,
  useId,
  useMemo,
} from 'react';
import Icon from 'src/components/common/Icon';
import './Checkbox.scss';

type Props = {
  checked: boolean;
  label: ReactNode;
  hideLabel?: boolean;
  disabled?: boolean;
  inputId?: string;
  className?: string;
  autoFocus?: boolean;
  required?: boolean;
  errorMessage?: string;
  showErrorMessage?: boolean;
  onChange?: (checked: boolean) => void;
  onFocus?: () => void;
  onMouseEnter?: (event: MouseEvent) => void;
  onMouseLeave?: (event: MouseEvent) => void;
};

function Checkbox(
  {
    label,
    hideLabel,
    checked = false,
    disabled,
    inputId: propsInputId,
    className,
    autoFocus,
    required,
    errorMessage = required ? 'Must be checked' : 'Must be unchecked',
    showErrorMessage,
    onFocus,
    onChange,
    onMouseEnter,
    onMouseLeave,
  }: Props,
  ref: Ref<HTMLSpanElement>,
) {
  const id = useId();
  const inputId = propsInputId ?? id;
  const showingError =
    typeof required === 'boolean' && required !== checked && showErrorMessage;
  const errorId = useMemo(
    () => (showingError ? `${id}__error` : undefined),
    [showingError, id],
  );

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event.target.checked);
  };

  return (
    <span
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={clsx([
        'checkbox-container',
        {
          'checkbox-container--checked': checked,
          'checkbox-container--disabled': disabled,
          'checkbox-container--hidden-label': hideLabel,
        },
        className,
      ])}
    >
      <label className="checkbox-container__label" htmlFor={inputId}>
        <span className="checkbox-container__checkbox-container">
          <span className="checkbox-container__checkbox">
            <Icon className="checkbox-container__check" name="Tick" />
          </span>
          <input
            required={required}
            className="checkbox-container__input"
            id={inputId}
            type="checkbox"
            checked={checked}
            disabled={disabled}
            onChange={handleInputChange}
            onFocus={onFocus}
            autoFocus={autoFocus}
            aria-invalid={showingError ? true : undefined}
            aria-errormessage={errorId}
          />
        </span>
        {label ? (
          <span className="checkbox-container__label-text">
            {label}
            {required && (
              <span
                className="checkbox-container__required-star"
                aria-label={required ? 'Must be checked' : 'Must be unchecked'}
              />
            )}
          </span>
        ) : null}

        {!disabled && showingError ? (
          <span className="checkbox-container__error" id={errorId} role="alert">
            {errorMessage}
          </span>
        ) : null}
      </label>
    </span>
  );
}

export default forwardRef(Checkbox);
