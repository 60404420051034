import {
  Profile,
  Roles,
  Scope,
  urls,
  Users,
} from '@motional-cc/fe/interface/api/user-profile-service';
import { captureException } from '@sentry/react';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo } from 'react';
import { useApi, useInfiniteApi, useMutateApi } from 'src/api/hooks/service';
import { user as userHost } from 'src/api/hosts';
import { useMessages } from 'src/components/Messages/messages-context';
import { isIncompleteStatus } from 'src/interface/command-center';
import { updateErrorMessage } from 'src/tools/object/updateErrorMessage';
import { convertToAbsolutePaths } from './utils';

// Roles which have a static name
const STATIC_ROLES = ['field_support_specialist'] as const;
type StaticRole = (typeof STATIC_ROLES)[number];

export const userPaths = convertToAbsolutePaths(userHost, urls);

const useGetRoles = () => {
  const response = useInfiniteApi<Roles.GetRoles.ResponseBody>(
    userPaths.Roles.GetRoles(),
    {
      isKnownToBeAnIrregularApi: true,
    },
  );

  return {
    roles: response?.result,
    ...response,
  };
};

const useGetHasScopes = () => {
  const { userProfile } = useUserProfile();
  // strigifying to prevent extra renders across the app
  const scopesString = userProfile?.scopes
    ? JSON.stringify([...userProfile.scopes].sort())
    : 'null';

  return useCallback(
    (
      scopesToCheck: Readonly<Scope[]> = [],
      { eitherScope = false }: { eitherScope?: boolean } = {},
    ) => {
      const scopes = JSON.parse(scopesString);

      if (!scopes) {
        return null;
      }

      if (scopesToCheck.length === 0) {
        return true;
      }

      return scopesToCheck[eitherScope ? 'some' : 'every'](
        (scope) => !!scopes?.includes(scope),
      );
    },
    [scopesString],
  );
};

const useHasScopes = (
  scopesToCheck: Readonly<Scope[]> = [],
  { eitherScope = false }: { eitherScope?: boolean } = {},
) => {
  const hasScopes = useGetHasScopes();

  return hasScopes(scopesToCheck, { eitherScope });
};

const useHasRole = (roleId: StaticRole) => {
  const { userProfile } = useUserProfile();
  const { result: assignedRoles, status } =
    useApi<Users.GetUserRoles.ResponseBody>(
      userPaths.Users.GetUserRoles(userProfile?.id ?? ''),
      { enabled: !!userProfile?.id },
    );

  if (isIncompleteStatus(status)) {
    return null;
  }

  return assignedRoles?.some((assignedRole) => assignedRole.id === roleId);
};

const useUserProfile = ({ enabled = true }: { enabled?: boolean } = {}) => {
  const { result: userProfile, ...rest } =
    useApi<Profile.GetProfile.ResponseBody>(userPaths.Profile.GetProfile(), {
      refetchOnWindowFocus: 'always',
      enabled,
    });

  return { userProfile, ...rest };
};

const syncUserProfile = (queryClient: QueryClient) => {
  queryClient.refetchQueries([userPaths.Profile.GetProfile()]);
};

const useMutateUserProfile = ({
  notifyStyle = 'noisy',
  failureStyle = notifyStyle === 'silent' ? 'fail-silently' : 'notify-on-fail',
  successStyle = notifyStyle === 'silent'
    ? 'succeed-silently'
    : 'notify-on-success',
}:
  | {
      notifyStyle?: 'noisy' | 'silent';
      failureStyle?: never;
      successStyle?: never;
    }
  | {
      notifyStyle?: 'manual';
      failureStyle?: 'notify-on-fail' | 'fail-silently';
      successStyle?: 'notify-on-success' | 'succeed-silently';
    } = {}) => {
  const { showMessage } = useMessages();
  const queryClient = useQueryClient();
  const mutator = useMutateApi<
    Profile.PatchProfile.ResponseBody,
    Profile.PatchProfile.RequestBody
  >(userPaths.Profile.PatchProfile(), 'PATCH');

  const putResult = mutator.response?.result;

  useEffect(
    function updateVersionFromPUT() {
      if (!putResult) return;

      queryClient.setQueryData<Profile.GetProfile.ResponseBody>(
        [userPaths.Profile.GetProfile()],
        (_currentProfile) => {
          const currentProfile =
            _currentProfile as Profile.GetProfile.ResponseBody;
          return {
            ...currentProfile,
            result: {
              ...currentProfile.result,
              settings: putResult.settings,
            },
          };
        },
      );
      // Our APIs are too inconsistent to trust building ONLY from mutation responses
      syncUserProfile(queryClient);
    },
    [putResult, queryClient],
  );

  const hydratedMutator = useMemo(() => {
    return {
      ...mutator,
      mutate: async (
        updatedProfile: Omit<Profile.PatchProfile.RequestBody, 'version'>,
      ) => {
        try {
          const mutationResponse = await mutator.mutate({
            ...updatedProfile,
          });
          if (successStyle === 'notify-on-success') {
            showMessage({
              type: 'success',
              title: 'Settings updated successfully',
            });
          }
          return mutationResponse;
        } catch (_error) {
          const error = _error as Error;
          if (failureStyle === 'notify-on-fail') {
            showMessage({
              type: 'error',
              title: 'Failed to update settings',
              description: error.message,
            });
          }
          captureException(
            updateErrorMessage(
              error,
              (message) =>
                `Failed to save ${
                  failureStyle === 'fail-silently' ? 'minor' : 'important'
                } user settings${message ? `: ${message}` : ''}`,
            ),
          );
          throw error;
        }
      },
    };
  }, [failureStyle, successStyle, mutator, showMessage]);

  return hydratedMutator;
};

export const userApi = {
  useGetHasScopes,
  useHasScopes,
  useUserProfile,
  useMutateUserProfile,
  syncUserProfile,
  useGetRoles,
  useHasRole,
};
